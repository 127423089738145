<template>
  <div v-loading="true" style="width: 100vw;height: 100vh"></div>
</template>

<script>
import {authService} from "@/app/user/auth/auth.service";

export default {
  name: 'confirm-email',
  computed: {
    token() {
      return this.$route.params.token
    }
  },
  mounted() {
    authService.confirmEmail(this.token)
        .finally(() => this.$router.push('/profile'))
  },
}
</script>

<style scoped lang="scss">

</style>
